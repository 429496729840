// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-apply-index-jsx": () => import("/opt/build/repo/src/pages/apply/index.jsx" /* webpackChunkName: "component---src-pages-apply-index-jsx" */),
  "component---src-pages-dashboard-index-js": () => import("/opt/build/repo/src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-index-js": () => import("/opt/build/repo/src/pages/journal/index.js" /* webpackChunkName: "component---src-pages-journal-index-js" */),
  "component---src-pages-origin-index-jsx": () => import("/opt/build/repo/src/pages/origin/index.jsx" /* webpackChunkName: "component---src-pages-origin-index-jsx" */),
  "component---src-pages-protocol-index-js": () => import("/opt/build/repo/src/pages/protocol/index.js" /* webpackChunkName: "component---src-pages-protocol-index-js" */)
}

